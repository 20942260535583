import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import MerchantSignUpButton from '../../components/merchantSignUpButton'
import { Link } from 'gatsby'

export default function ZapietAndTrexity () {
  const shareTitle = 'Trexity same-day on demand delivery platform launches in Winnipeg'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Trexity same-day on demand delivery platform launches in Winnipeg</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='announcement'>Announcement</span>
                  <span>December 1, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/christina-alok.jpg" alt="Christina, General Manager - and Alok, CEO" />
                <figcaption>
                  <span className='mice'>Alok, CEO - Christina, General Manager (Photo by: Mikaela MacKenzie / Winnipeg Free Press)</span>
                </figcaption>

                <p>Trexity, a same-day on-demand local delivery technology company, today announced that it has officially launched in Winnipeg, Manitoba. This marks the third operating city for the company in Canada and the first in the province of Manitoba.</p>
                <p>With services in Toronto and Ottawa, Winnipeg is the next step in the company’s national expansion that will include forays into Calgary, Edmonton and Halifax in the coming months. </p>

                <blockquote className='quote'>
                  Manitoba capital chosen as third operating city in Canada”
                </blockquote>

                <p>“Trexity is committed to providing a concierge-level delivery service for all the small and medium-sized merchants in Winnipeg.” says Alok Ahuja, the company’s co-founder and CEO. “The urgency of shopping locally today is heightened. Winnipeg shoppers expect their favourite merchants to provide an easy and convenient shopping experience as well as have the ability to deliver their purchases immediately.  This is about; local helping local.”</p>

                <img src="/img/press/christina-alok-nikhil.jpg" alt="Alok, Christina, and Nikhil" />
                <figcaption>
                  <span className='mice'>Alok, CEO - Christina, General Manager - Nikhil, Driver Support</span>
                </figcaption>

                <p>Trexity allows merchants to effortlessly offer real-time delivery to their local customers via a fleet of independent contract drivers. Drivers earn between $25&ndash;$30 per hour while delivering for Trexity, making it one of the most generous delivery platforms in Canada.</p>

                <p className='mb'>Founded in 2019 by ex-Shopify exec Alok Ahuja and LaunchFort co-founders Mathieu Bouchard and Darren Schnare, the company has been able to quickly build and scale the platform while helping their marquee local merchants thrive during the pandemic.</p>

                <MerchantSignUpButton />
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/zapiet-and-trexity-partner-up" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Zapiet and Trexity partner up to offer Merchants same-day local delivery</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
